<template>
  <a-card :bordered="false" class="card-area">
      <a-form layout="horizontal">
        <div :class="advanced ? null: 'fold'">
          <a-row>
            <a-col :md="12" :sm="24" >
              <a-form-item
                label="操作人"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 18, offset: 1}">
                <a-input v-model.trim="queryParams.username"/>
              </a-form-item>
            </a-col>
         </a-row>
          <a-row v-if="advanced">
            <a-col :md="12" :sm="24" >
              <a-form-item
                label="操作时间"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 18, offset: 1}">
                <range-date @change="handleDateChange" ref="createTime" style="width: 100%"></range-date>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
        <span style="float: right; margin-top: 3px;">
          <a-button type="primary" @click="search">查询</a-button>
          <a-button style="margin-left: 8px" @click="reset">重置</a-button>
          <a @click="toggleAdvanced" style="margin-left: 8px">
            {{advanced ? '收起' : '展开'}}
            <a-icon :type="advanced ? 'up' : 'down'" />
          </a>
        </span>
      </a-form>
    <div>
      <div class="operator">
        <a-button v-hasPermission="['log:delete']" @click="batchDelete" type="primary" ghost>删除</a-button>
        <a-dropdown v-hasPermission="['log:export']">
          <a-menu slot="overlay">
            <a-menu-item key="export-data" @click="exprotExccel">导出Excel</a-menu-item>
          </a-menu>
          <a-button>
            更多操作 <a-icon type="down" />
          </a-button>
        </a-dropdown>
      </div>
      <!-- 表格区域 -->
      <a-table bordered ref="TableInfo"
               :columns="columns"
               :rowKey="record => record.id"
               :dataSource="dataSource"
               :pagination="pagination"
               :loading="loading"
               :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
               @change="handleTableChange" :scroll="{ x: 1400 }">
        <template slot="responseText" slot-scope="text">
          <a-popover placement="topRight">
            <template slot="content">
              <div style="word-break: break-all; text-align: justify">{{text}}</div>
            </template>
            <p style="width: 200px;margin-bottom: 0">{{text}}</p>
          </a-popover>
        </template>
        <template slot="requestUrl" slot-scope="text">
          <a-popover placement="topLeft">
            <template slot="content">
              <div>{{text}}</div>
            </template>
            <p style="width: 200px;margin-bottom: 0">{{text}}</p>
          </a-popover>
        </template>
        <template slot="requestParams" slot-scope="text">
          <a-popover placement="topLeft">
            <template slot="content">
              <div style="max-width: 300px;">{{text}}</div>
            </template>
            <p style="width: 200px;margin-bottom: 0">{{text}}</p>
          </a-popover>
        </template>
      </a-table>
    </div>
  </a-card>
</template>

<script>
import RangeDate from '@/components/datetime/RangeDate'

export default {
  name: 'SystemLog',
  components: {RangeDate},
  data () {
    return {
      advanced: false,
      dataSource: [],
      sortedInfo: null,
      paginationInfo: null,
      selectedRowKeys: [],
      queryParams: {},
      pagination: {
        pageSizeOptions: ['10', '20', '30', '40', '100'],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      loading: false
    }
  },
  computed: {
    columns () {
      let { sortedInfo } = this
      sortedInfo = sortedInfo || {}
      return [{
        title: '操作人',
        dataIndex: 'username'
      }, {
        title: '请求地址',
        dataIndex: 'requestUrl',
        scopedSlots: { customRender: 'requestUrl' }
      }, {
        title: '请求参数',
        dataIndex: 'requestParams',
        scopedSlots: { customRender: 'requestParams' }
      }, {
        title: '耗时',
        dataIndex: 'time',
        customRender: (text, row, index) => {
          if (text < 500) {
            return <a-tag color="green">{text} ms</a-tag>
          } else if (text < 1000) {
            return <a-tag color="cyan">{text} ms</a-tag>
          } else if (text < 1500) {
            return <a-tag color="orange">{text} ms</a-tag>
          } else {
            return <a-tag color="red">{text} ms</a-tag>
          }
        },
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'time' && sortedInfo.order
      }, {
        title: '应答码',
        dataIndex: 'responseCode',
        customRender: (text, row, index) => {
          if (text === 200) {
            return <a-tag color="green">{text}</a-tag>
          } else if (text !== '' && text !== null) {
            return <a-tag color="red">{text}</a-tag>
          }
        }
      }, {
        title: '应答内容',
        dataIndex: 'responseText',
        scopedSlots: { customRender: 'responseText' }
      }, {
        title: '操作时间',
        dataIndex: 'createTime',
        /* fixed: 'right',
        width: 200, */
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'createTime' && sortedInfo.order,
        customRender: (text) => { return this.datetime(text) },
      }]
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
      if (!this.advanced) {
        this.queryParams.createTimeFrom = ''
        this.queryParams.createTimeTo = ''
        this.queryParams.location = ''
      }
    },
    handleDateChange (value) {
      if (value) {
        this.queryParams.createTimeFrom = value[0]
        this.queryParams.createTimeTo = value[1]
      }
    },
    batchDelete () {
      if (!this.selectedRowKeys.length) {
        this.$message.warning('请选择需要删除的记录')
        return
      }
      let that = this
      this.$confirm({
        title: '确定删除所选中的记录?',
        content: '当您点击确定按钮后，这些记录将会被彻底删除',
        centered: true,
        onOk () {
          let ids = that.selectedRowKeys.join(',')
          that.$delete('log/' + ids).then(() => {
            that.$message.success('删除成功')
            that.selectedRowKeys = []
            that.reset()
          })
        },
        onCancel () {
          that.selectedRowKeys = []
        }
      })
    },
    exprotExccel () {
      let {sortedInfo} = this
      let sortField, sortOrder
      // 获取当前列的排序和列的过滤规则
      if (sortedInfo) {
        sortField = sortedInfo.field
        sortOrder = sortedInfo.order
      }
      this.$export('log/excel', {
        sortField: sortField,
        sortOrder: sortOrder,
        pageNum: 1,
        pageSize: this.$root.exportMaxCount,
        type: 1,
        ...this.queryParams
      })
    },
    search () {
      // 点击查询按钮，重置所有分页、排序
      this.paginationInfo = null
      // 重置分页
      this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent
      // 重置排序
      this.sortedInfo = null
      let {sortedInfo} = this
      let sortField, sortOrder
      // 获取当前列的排序和列的过滤规则
      if (sortedInfo) {
        sortField = sortedInfo.field
        sortOrder = sortedInfo.order
      }
      this.fetch({
        sortField: sortField,
        sortOrder: sortOrder,
        ...this.queryParams
      })
    },
    reset () {
      // 取消选中
      this.selectedRowKeys = []
      // 重置分页
      this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent
      if (this.paginationInfo) {
        this.paginationInfo.current = this.pagination.defaultCurrent
        this.paginationInfo.pageSize = this.pagination.defaultPageSize
      }
      // 重置列排序规则
      this.sortedInfo = null
      // 重置查询参数
      this.queryParams = {}
      // 清空时间选择
      if (this.advanced) {
        this.$refs.createTime.reset()
      }
      this.fetch()
    },
    handleTableChange (pagination, filters, sorter) {
      // 将这两个参数赋值给Vue data，用于后续使用
      this.paginationInfo = pagination
      this.sortedInfo = sorter
      this.fetch({
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...this.queryParams,
        ...filters
      })
    },
    fetch (params = {}) {
      this.loading = true
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize
        params.pageSize = this.paginationInfo.pageSize
        params.pageNum = this.paginationInfo.current
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize
        params.pageNum = this.pagination.defaultCurrent
      }
      params.type = 1
      this.$get('log', {
        ...params
      }).then((r) => {
        let data = r.data
        const pagination = { ...this.pagination }
        pagination.total = data.total
        this.loading = false
        this.dataSource = data.rows
        this.pagination = pagination
      }).catch((e) => {
        console.error(e)
        this.loading = false
      })
    }
  }
}
</script>
<style lang="less" scoped>
  @import "../../utils/Common.less";
</style>
